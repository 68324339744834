<div class="main" id="circuit">
  <div class="row">
    <div class="content">
      <div class="contentImage">
        <img src={{players}} alt="" />
      </div>
      <div class="contentInformation">
        <div class="contentInformationTitle">
          {{"INTRODUCTION.TITLE" | translate}}
        </div>
        <div class="contentInformationParagraph">
          <p [innerHTML]="'INTRODUCTION.PARAGRAPH' | translate"></p>
        </div>
        <div class="contentInformationKeyPoints">
          <div class="contentInformationKeyPointItem">
            <div class="contentInformationKeyPointItemIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34.868 34.875"
                class="icon">
                <path
                  d="M23.66 10.687C22.641 4.408 20.236 0 17.438 0s-5.2 4.409-6.223 10.688zm-12.972 6.75a44.071 44.071 0 00.232 4.5h13.028a44.071 44.071 0 00.232-4.5 44.071 44.071 0 00-.232-4.5H10.92a44.071 44.071 0 00-.232 4.5zm22.83-6.75A17.474 17.474 0 0022.409.731c1.716 2.377 2.9 5.955 3.516 9.956zM12.459.731a17.463 17.463 0 00-11.1 9.956h7.592c.612-4 1.793-7.58 3.509-9.956zM34.27 12.937h-8.065c.148 1.477.232 2.988.232 4.5s-.084 3.023-.232 4.5h8.058a17.287 17.287 0 00.6-4.5 17.589 17.589 0 00-.593-4.5zm-25.832 4.5c0-1.512.084-3.023.232-4.5H.6a17.047 17.047 0 000 9h8.063a47.489 47.489 0 01-.225-4.5zm2.777 6.75c1.02 6.279 3.424 10.688 6.223 10.688s5.2-4.409 6.223-10.687zm11.2 9.956a17.491 17.491 0 0011.11-9.956h-7.594c-.619 4-1.8 7.58-3.516 9.956zM1.357 24.187a17.474 17.474 0 0011.109 9.956c-1.716-2.377-2.9-5.955-3.516-9.956H1.357z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div class="contentInformationKeyPointItemLabel">
              {{"INTRODUCTION.GLOBAL" | translate}}
            </div>
          </div>
          <div class="contentInformationKeyPointItem">
            <div class="contentInformationKeyPointItemIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40.5 27"
                class="icon">
                <path
                  d="M9 6.75h22.5v13.5H9zm28.125 6.75a3.375 3.375 0 003.375 3.375v6.75A3.375 3.375 0 0137.125 27H3.375A3.375 3.375 0 010 23.625v-6.75a3.375 3.375 0 000-6.75v-6.75A3.375 3.375 0 013.375 0h33.75A3.375 3.375 0 0140.5 3.375v6.75a3.375 3.375 0 00-3.375 3.375zM33.75 6.188A1.687 1.687 0 0032.063 4.5H8.438A1.687 1.687 0 006.75 6.188v14.625A1.687 1.687 0 008.438 22.5h23.625a1.687 1.687 0 001.688-1.687z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div class="contentInformationKeyPointItemLabel">
              {{"INTRODUCTION.FEE" | translate}}
            </div>
          </div>
          <div class="contentInformationKeyPointItem">
            <div class="contentInformationKeyPointItemIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 31.5 36"
                class="icon">
                <path
                  d="M0 32.625A3.376 3.376 0 003.375 36h24.75a3.376 3.376 0 003.375-3.375V13.5H0zm22.5-13.781a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm0 9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm-9-9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm0 9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm-9-9A.846.846 0 015.344 18h2.812a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844H5.344a.846.846 0 01-.844-.844zm0 9A.846.846 0 015.344 27h2.812a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844H5.344a.846.846 0 01-.844-.844zM28.125 4.5H24.75V1.125A1.128 1.128 0 0023.625 0h-2.25a1.128 1.128 0 00-1.125 1.125V4.5h-9V1.125A1.128 1.128 0 0010.125 0h-2.25A1.128 1.128 0 006.75 1.125V4.5H3.375A3.376 3.376 0 000 7.875v3.375h31.5V7.875A3.376 3.376 0 0028.125 4.5z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div class="contentInformationKeyPointItemLabel">
              {{"INTRODUCTION.DATES" | translate}}
            </div>
          </div>
        </div>
        <div class="contentInformationActions">
          <a href={{routes.EVENTS}} class="primaryAction">
            {{"INTRODUCTION.CHECK_EVENTS" | translate}}
          </a>
          <a class="secondaryAction"
            target="__blank"
            href={{routes.ABOUT_VGC}}
            rel="noopener noreferrer"
          >
            {{"INTRODUCTION.VGC" | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="contentReversed">
      <div class="contentImageReversed">
        <img src={{trophies}} alt="" />
      </div>
      <div class="contentInformation">
        <div class="contentInformationTitle">
        {{"INFO.TITLE" | translate}}
        </div>
        <div class="contentInformationParagraph">
          <p [innerHTML]="'INFO.PARAGRAPH' | translate"></p>
        </div>
        <div class="contentInformationKeyPoints">
          <div class="contentInformationKeyPointItem">
            <div class="contentInformationKeyPointItemIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34.868 34.875"
                class="icon">
                <path
                  d="M23.66 10.687C22.641 4.408 20.236 0 17.438 0s-5.2 4.409-6.223 10.688zm-12.972 6.75a44.071 44.071 0 00.232 4.5h13.028a44.071 44.071 0 00.232-4.5 44.071 44.071 0 00-.232-4.5H10.92a44.071 44.071 0 00-.232 4.5zm22.83-6.75A17.474 17.474 0 0022.409.731c1.716 2.377 2.9 5.955 3.516 9.956zM12.459.731a17.463 17.463 0 00-11.1 9.956h7.592c.612-4 1.793-7.58 3.509-9.956zM34.27 12.937h-8.065c.148 1.477.232 2.988.232 4.5s-.084 3.023-.232 4.5h8.058a17.287 17.287 0 00.6-4.5 17.589 17.589 0 00-.593-4.5zm-25.832 4.5c0-1.512.084-3.023.232-4.5H.6a17.047 17.047 0 000 9h8.063a47.489 47.489 0 01-.225-4.5zm2.777 6.75c1.02 6.279 3.424 10.688 6.223 10.688s5.2-4.409 6.223-10.687zm11.2 9.956a17.491 17.491 0 0011.11-9.956h-7.594c-.619 4-1.8 7.58-3.516 9.956zM1.357 24.187a17.474 17.474 0 0011.109 9.956c-1.716-2.377-2.9-5.955-3.516-9.956H1.357z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div class="contentInformationKeyPointItemLabel">
              {{"INFO.PLACE" | translate}}
            </div>
          </div>
          <div class="contentInformationKeyPointItem">
            <div class="contentInformationKeyPointItemIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 31.5 36"
                class="icon">
                <path
                  d="M0 32.625A3.376 3.376 0 003.375 36h24.75a3.376 3.376 0 003.375-3.375V13.5H0zm22.5-13.781a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm0 9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm-9-9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm0 9a.846.846 0 01.844-.844h2.813a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844h-2.813a.846.846 0 01-.844-.844zm-9-9A.846.846 0 015.344 18h2.812a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844H5.344a.846.846 0 01-.844-.844zm0 9A.846.846 0 015.344 27h2.812a.846.846 0 01.844.844v2.813a.846.846 0 01-.844.844H5.344a.846.846 0 01-.844-.844zM28.125 4.5H24.75V1.125A1.128 1.128 0 0023.625 0h-2.25a1.128 1.128 0 00-1.125 1.125V4.5h-9V1.125A1.128 1.128 0 0010.125 0h-2.25A1.128 1.128 0 006.75 1.125V4.5H3.375A3.376 3.376 0 000 7.875v3.375h31.5V7.875A3.376 3.376 0 0028.125 4.5z"
                  fill="currentColor"/>
              </svg>
            </div>
            <div class="contentInformationKeyPointItemLabel">
              {{"INFO.DATES" | translate}}
            </div>
          </div>
        </div>
        <div class="contentInformationActions">
          <a href={{routes.PRIZES}} class="primaryAction">
            {{"INFO.PRIZES" | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
