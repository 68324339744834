import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

  public bigDesktopDecoration: string = '/assets/spectrier.png';

  public PLAYERS = [{
    flag: "spain",
    name: "Victor Medina",
    q1: '400',
    q2: '-',
    q3: '-',
    q4: '-',
    q5: '-',
    total: '400',
  }];

  showAllPoints(): void {

  }

  loadPlayerList(): void {
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'keyLpNidd8nrJB9g7'}).base('appPdOJJT1sUwgOmY');

    var result: any[] = [];
    base('Spring Ranking').select({
        // Selecting the first 32 records in Grid view:
        maxRecords: 200,
        view: "Grid view"
    }).eachPage(function page(records: any, fetchNextPage: any) {
        // This function (`page`) will get called for each page of records.

        records.forEach(function(record: any) {
            console.log('Retrieved', record.get('Name'));
            result.push({
              flag: '/assets/flags/' + record.get("Flag") + '.png',
              name: record.get('Name'),
              q1: record.get("Q1"),
              q2: record.get("Q2"),
              q3: record.get("Q3"),
              q4: record.get("Q4"),
              q5: record.get("Q5"),
              total: record.get("Total")
            });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();

    }, function done(err: any) {
        if (err) { console.error(err); return; }
    });

    console.log(result);
    this.PLAYERS = result;
  }

  constructor() { }

  ngOnInit(): void {
    this.loadPlayerList();
    var coll = document.getElementsByClassName("collapsible");
    var i: any;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        coll[i].classList.toggle("active");
        var content: any = coll[i].nextElementSibling;
        if (content.style.overflow == "auto"){
          content.style.overflow = "hidden";
        } else {
          content.style.overflow = "auto";
        }
      });
    }
  }

}
