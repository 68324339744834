<div class="main" id="ranking">
  <div class="row">
    <div class="content">
      <h4 class="title">GLOBAL RANKING</h4>
    </div>
    <table class="ranking">
    	<thead>
    	<tr>
    		<th class="tableTitle">PLACE</th>
        <th class="tableTitle"></th>
    		<th class="tableTitle">PLAYER</th>
    		<th class="tableTitleInfo">Q1</th>
    		<th class="tableTitleInfo">Q2</th>
    		<th class="tableTitleInfo">Q3</th>
    		<th class="tableTitleInfo">Q4</th>
    		<th class="tableTitleInfo">Q5</th>
    		<th class="tableTitle">TOTAL</th>
    	</tr>
    	</thead>
    	<tbody>
        <tr class="tableItem" *ngFor="let player of PLAYERS; let i = index" [attr.data-index]="i">
          <td class="tableItemTitle">{{i+1}}</td>
          <td class="tableItemTitle"><img class="playerFlag" src={{player.flag}} /></td>
          <td class="tableItemTitle">{{player.name}}</td>
          <td class="tableItemTitleInfo">{{player.q1}}</td>
          <td class="tableItemTitleInfo">{{player.q2}}</td>
          <td class="tableItemTitleInfo">{{player.q3}}</td>
          <td class="tableItemTitleInfo">{{player.q4}}</td>
          <td class="tableItemTitleInfo">{{player.q5}}</td>
          <td class="tableItemTitle">{{player.total}}</td>
        </tr>
    	</tbody>
    </table>
  </div>
  <img class="bigDesktopDecoarationImg" src={{bigDesktopDecoration}} alt="" />
</div>
