import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { routes } from '../../routes';

@Component({
  selector: 'app-score-system',
  templateUrl: './score-system.component.html',
  styleUrls: ['./score-system.component.scss']
})
export class ScoreSystemComponent implements OnInit {

  public bigDesktopDecoration: string = '/assets/glastrier.png';
  public SCORE_SYSTEM = [
    {
      title: '1st place',
      points: '400 VR points',
    },
    {
      title: '2nd place',
      points: '300 VR points',
    },
    {
      title: '3-4th place',
      points: '200 VR points',
    },
    {
      title: '5-8th place',
      subtitle: '',
      points: '150 VR points',
    },
    {
      title: '9-16th place',
      subtitle: '',
      points: '80 VR points',
    },
    {
      title: '17-32th place',
      subtitle: '',
      points: '40 VR points',
    },
    {
      title: '33-64th place',
      subtitle: '',
      points: '20 VR points',
    }
  ];

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
  }

}
