<div class="main">
  <div class="mobileContainer">
    <div class="row">
      <div class="mainMobile">
        <div class="logo">
          <img src={{logo}} alt="Victory Road logo" />
        </div>
        <div class="socialMediaList">
          <a class="socialMediaButton"
            href="https://www.facebook.com/VictoryRoadVGC/"
            target="__blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8.89 19.161"
              style="width: '100%', height: '100%'"
            >
              <path
                d="M8.54 9.593H5.87v9.569H1.885V9.593H0V6.221h1.883v-2.19a4.739 4.739 0 01.307-1.73 3.361 3.361 0 011.2-1.6 4.2 4.2 0 012.5-.7l2.956.022v3.285H6.7a.849.849 0 00-.547.175.922.922 0 00-.285.745v1.993H8.89z"
                fill="#fff"
              />
            </svg>
          </a>
          <a class="socialMediaButton"
            href="https://twitter.com/VictoryRoadES"
            target="__blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26.724 21.692"
              style="width: '100%', height: '100%'"
            >
              <path
                d="M23.978 5.431v.7a15.857 15.857 0 01-1.007 5.491 16.371 16.371 0 01-2.99 5 14.835 14.835 0 01-4.881 3.662 15.922 15.922 0 01-6.681 1.4 15.623 15.623 0 01-4.454-.64 15.149 15.149 0 01-3.966-1.8q.641.061 1.312.061a10.889 10.889 0 003.63-.61 11.377 11.377 0 003.173-1.708 5.478 5.478 0 01-3.173-1.114 5.284 5.284 0 01-1.922-2.7 5.453 5.453 0 001.007.091 5.659 5.659 0 001.434-.183 5.3 5.3 0 01-2.227-1.022 5.438 5.438 0 01-1.571-1.923 5.71 5.71 0 01-.595-2.425V7.62a5.138 5.138 0 002.5.7 5.518 5.518 0 01-1.785-1.968 5.36 5.36 0 01-.656-2.608A5.447 5.447 0 011.858.998a15.643 15.643 0 0011.287 5.734 6.342 6.342 0 01-.122-1.251 5.338 5.338 0 01.732-2.746 5.526 5.526 0 012-2 5.277 5.277 0 012.73-.748 5.43 5.43 0 014 1.74A10.662 10.662 0 0025.963.384a5.3 5.3 0 01-.93 1.754 5.635 5.635 0 01-1.48 1.3 11.142 11.142 0 003.173-.885 11.466 11.466 0 01-2.748 2.878z"
                fill="#fff"
              />
            </svg>
          </a>
          <a class="socialMediaButton"
            href="https://www.youtube.com/channel/UCEA_HtxU04QUL-IIkuOb6Fw"
            target="__blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26.663 18.728"
              style="width: '100%', height: '100%'"
            >
              <path
                d="M26.663 9.366q0 2.227-.03 2.776-.122 1.8-.244 2.624a6.431 6.431 0 01-.4 1.495 2.991 2.991 0 01-.732 1.159 3.513 3.513 0 01-1.861.976q-5.8.428-12.355.305-3.844-.062-5.476-.138a18.25 18.25 0 01-2.425-.229 3.567 3.567 0 01-1.8-.945 4.148 4.148 0 01-.854-1.677 5.729 5.729 0 01-.229-.946Q.18 14.277.12 13.302a62.968 62.968 0 010-7.871l.183-1.495a7.1 7.1 0 01.335-1.464 3.351 3.351 0 01.793-1.22 3.9 3.9 0 011.8-.915A30.93 30.93 0 016.938.093Q9.881.001 13.329.001t6.391.107q2.943.108 3.737.229a3.711 3.711 0 011.174.458 3.02 3.02 0 01.9.793 5.538 5.538 0 01.7 1.647 9.238 9.238 0 01.305 1.739q0 .153.092 1.617c.024.365.035 1.295.035 2.775zm-12.294 1.495q2.409-1.219 3.417-1.769l-7.169-3.783v7.535q1.25-.698 3.752-1.983z"
                fill="#fff"
              />
            </svg>
          </a>
          <a class="socialMediaButton"
            href="https://www.twitch.tv/victoryroades"
            target="__blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25.504 26.664"
              style="width: '100%', height: '100%'"
            >
              <path
                d="M25.504 0v16.23l-6.956 6.956h-5.217l-3.478 3.479H6.375v-3.479H0V4.638L1.739.001h23.765zm-2.319 2.319H4.057v16.81H9.27v3.479l3.478-3.479h6.376l4.057-4.057zM10.433 13.912h2.318V6.956h-2.318zm6.376 0h2.319V6.956h-2.319z"
                fill="#fff"
              />
            </svg>
          </a>
        </div>
        <p class="copyright">{{"FOOTER.COPYRIGHT.SHORT" | translate}}</p>
        <nav class="footerNavigation">
          <a href={{routes.HELP}}>
            {{"FOOTER.HELP" | translate}}
          </a>
          <a href={{routes.SIGNUP_CONDITIONS}}>
            {{"FOOTER.PARTICIPATION" | translate}}
          </a>
          <a href={{routes.LEGAL_NOTICE}}>
            {{"FOOTER.LEGAL" | translate}}
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="desktopContainer">
    <div class="row">
      <div class="mainDesktop">
        <div class="left">
          <div class="logo">
            <img src={{logo}} alt="Victory Road logo" />
          </div>
          <p class="copyright">
            {{"FOOTER.COPYRIGHT.LONG" | translate}}
          </p>
        </div>
        <div class="right">
          <div class="socialMediaList">
            <a class="socialMediaButton"
              href="https://www.facebook.com/VictoryRoadVGC/"
              target="__blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8.89 19.161"
                style="width: '100%', height: '100%'"
              >
                <path
                  d="M8.54 9.593H5.87v9.569H1.885V9.593H0V6.221h1.883v-2.19a4.739 4.739 0 01.307-1.73 3.361 3.361 0 011.2-1.6 4.2 4.2 0 012.5-.7l2.956.022v3.285H6.7a.849.849 0 00-.547.175.922.922 0 00-.285.745v1.993H8.89z"
                  fill="#fff"
                />
              </svg>
            </a>
            <a class="socialMediaButton"
              href="https://twitter.com/VictoryRoadES"
              target="__blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26.724 21.692"
                style="width: '100%', height: '100%'"
              >
                <path
                  d="M23.978 5.431v.7a15.857 15.857 0 01-1.007 5.491 16.371 16.371 0 01-2.99 5 14.835 14.835 0 01-4.881 3.662 15.922 15.922 0 01-6.681 1.4 15.623 15.623 0 01-4.454-.64 15.149 15.149 0 01-3.966-1.8q.641.061 1.312.061a10.889 10.889 0 003.63-.61 11.377 11.377 0 003.173-1.708 5.478 5.478 0 01-3.173-1.114 5.284 5.284 0 01-1.922-2.7 5.453 5.453 0 001.007.091 5.659 5.659 0 001.434-.183 5.3 5.3 0 01-2.227-1.022 5.438 5.438 0 01-1.571-1.923 5.71 5.71 0 01-.595-2.425V7.62a5.138 5.138 0 002.5.7 5.518 5.518 0 01-1.785-1.968 5.36 5.36 0 01-.656-2.608A5.447 5.447 0 011.858.998a15.643 15.643 0 0011.287 5.734 6.342 6.342 0 01-.122-1.251 5.338 5.338 0 01.732-2.746 5.526 5.526 0 012-2 5.277 5.277 0 012.73-.748 5.43 5.43 0 014 1.74A10.662 10.662 0 0025.963.384a5.3 5.3 0 01-.93 1.754 5.635 5.635 0 01-1.48 1.3 11.142 11.142 0 003.173-.885 11.466 11.466 0 01-2.748 2.878z"
                  fill="#fff"
                />
              </svg>
            </a>
            <a class="socialMediaButton"
              href="https://www.youtube.com/channel/UCEA_HtxU04QUL-IIkuOb6Fw"
              target="__blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26.663 18.728"
                style="width: '100%', height: '100%'"
              >
                <path
                  d="M26.663 9.366q0 2.227-.03 2.776-.122 1.8-.244 2.624a6.431 6.431 0 01-.4 1.495 2.991 2.991 0 01-.732 1.159 3.513 3.513 0 01-1.861.976q-5.8.428-12.355.305-3.844-.062-5.476-.138a18.25 18.25 0 01-2.425-.229 3.567 3.567 0 01-1.8-.945 4.148 4.148 0 01-.854-1.677 5.729 5.729 0 01-.229-.946Q.18 14.277.12 13.302a62.968 62.968 0 010-7.871l.183-1.495a7.1 7.1 0 01.335-1.464 3.351 3.351 0 01.793-1.22 3.9 3.9 0 011.8-.915A30.93 30.93 0 016.938.093Q9.881.001 13.329.001t6.391.107q2.943.108 3.737.229a3.711 3.711 0 011.174.458 3.02 3.02 0 01.9.793 5.538 5.538 0 01.7 1.647 9.238 9.238 0 01.305 1.739q0 .153.092 1.617c.024.365.035 1.295.035 2.775zm-12.294 1.495q2.409-1.219 3.417-1.769l-7.169-3.783v7.535q1.25-.698 3.752-1.983z"
                  fill="#fff"
                />
              </svg>
            </a>
            <a class="socialMediaButton"
              href="https://www.twitch.tv/victoryroades"
              target="__blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25.504 26.664"
                style="width: '100%', height: '100%'"
              >
                <path
                  d="M25.504 0v16.23l-6.956 6.956h-5.217l-3.478 3.479H6.375v-3.479H0V4.638L1.739.001h23.765zm-2.319 2.319H4.057v16.81H9.27v3.479l3.478-3.479h6.376l4.057-4.057zM10.433 13.912h2.318V6.956h-2.318zm6.376 0h2.319V6.956h-2.319z"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>
          <nav class="footerNavigation">
            <a href={{routes.HELP}}>
              {{"FOOTER.HELP" | translate}}
            </a>
            <a href={{routes.SIGNUP_CONDITIONS}}>
              {{"FOOTER.PARTICIPATION" | translate}}
            </a>
            <a href={{routes.LEGAL_NOTICE}}>
              {{"FOOTER.LEGAL" | translate}}
            </a>
          </nav>
        </div>
        </div>
    </div>
  </div>
</div>
