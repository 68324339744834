<div class="main" id="events">
  <div class="row">
    <div class="header">
      <h1 class="title">{{"EVENT.TITLE" | translate}}</h1>
      <h3 [innerHTML]="'EVENT.PARAGRAPH' | translate" class="paragraph">
      </h3>
    </div>
  </div>

  <div class="mobileEventsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let event of EVENTS">
          <app-event-card [event]="event"></app-event-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopEventsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let event of EVENTS">
          <app-event-card [event]="event"></app-event-card>
        </div>
      </ul>
    </div>
  </div>
</div>
