import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public logo: string = '/assets/vr-logo-footer.png';

  public routes = {
    // Home page ids
    INTRODUCTION: '/#circuit',
    EVENTS: '/#events',
    SCORE_SYSTEM: '/#score',
    PRIZES: '/#prizes',

    // Internal pages
    HELP: '/help',
    SIGNUP_CONDITIONS: '/signup_conditions',
    LEGAL_NOTICE: '/legal-notice',

    // External pages
    TRAILER: 'https://www.youtube.com/watch?v=74P_nt1thps',
    ABOUT_VGC: 'https://victoryroadvgc.com/en/pokemon-vgc/',
    VICTORY_ROAD: 'https://victoryroadvgc.com/',

    // Docs
    SIGNUP_CONDITIONS_PDF: encodeURIComponent(
      'Victory Road Circuit - Signup conditions.pdf'
    ),
    LEGAL_NOTICE_PDF: encodeURIComponent('Victory Road Circuit - Legal notice.pdf'),
  };

  constructor() { }

  ngOnInit(): void {
  }

}
