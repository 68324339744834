import { Component, OnInit } from '@angular/core';
import { routes } from '../../routes';

@Component({
  selector: 'app-desktopmenu',
  templateUrl: './desktopmenu.component.html',
  styleUrls: ['./desktopmenu.component.scss']
})
export class DesktopmenuComponent implements OnInit {

  public routes = routes;

  constructor() { }

  ngOnInit(): void {

  }

}
