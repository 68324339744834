import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  public EVENTS = [
    {
      logo: 'assets/vr-logo-small.png',
      title: 'First Qualifier',
      dates: '???',
      timezone: '???',
      buttonLabel: 'Results',
      buttonHref:
        '#',
      info: 'This tournament has finished',
      infoMobile: 'Tournament finished',
      isActive: false,
      isFinished: true,
    },
    {
      logo: 'assets/vr-logo-small.png',
      title: 'Second Qualifier',
      dates: '???',
      timezone: '???',
      buttonLabel: 'Results',
      buttonHref:
        '#',
      info: 'This tournament has finished',
      infoMobile: 'Tournament finished',
      isActive: false,
      isFinished: true,
    },
    {
      logo: 'assets/vr-logo-small.png',
      title: 'Third Qualifier',
      dates: '???',
      timezone: '???',
      buttonLabel: 'Results',
      buttonHref:
        '#',
      info: 'This tournament has finished',
      infoMobile: 'Tournament finished',
      isActive: false,
      isFinished: true,
    }
  ];

  loadEventsList(): void {
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'keyLpNidd8nrJB9g7'}).base('appPdOJJT1sUwgOmY');

    var result: any[] = [];
    base('Events').select({
        // Selecting the first 5 records in Grid view:
        maxRecords: 5,
        view: "Grid view"
    }).eachPage(function page(records: any, fetchNextPage: any) {
        // This function (`page`) will get called for each page of records.

        records.forEach(function(record: any) {
            console.log('Retrieved', record.get('Name'));
            result.push({
              logo: 'assets/vr-logo-small.png',
              title: record.get('Title'),
              dates: record.get('Dates'),
              timezone: record.get('Timezone'),
              buttonLabel: record.get('ButtonText'),
              buttonHref:
                record.get('URL'),
              info: record.get('InfoText'),
              infoMobile: record.get('InfoTextMobile'),
              isActive: record.get('IsActive'),
              isFinished: record.get('IsFinished')
            });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();

    }, function done(err: any) {
        if (err) { console.error(err); return; }
    });

    console.log(result);
    this.EVENTS = result;
  }

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.loadEventsList();
  }

}
