export const routes = {
  // Home page ids
  INTRODUCTION: '/#circuit',
  EVENTS: '/#events',
  SCORE_SYSTEM: '/#score',
  PRIZES: '/#prizes',
  RANKING: '/ranking',
  RULESET: 'https://victoryroadvgc.com/wp-content/uploads/2021/04/Victory-Road-Circuit-Rules.pdf',

  // Internal pages
  HELP: '/help',
  SIGNUP_CONDITIONS: '/signup_conditions',
  LEGAL_NOTICE: '/legal-notice',

  // External pages
  TRAILER: 'https://www.youtube.com/watch?v=V2DwJEIB19I',
  ABOUT_VGC: 'https://victoryroadvgc.com/en/pokemon-vgc/',
  VICTORY_ROAD: 'https://victoryroadvgc.com/',

  // Docs
  SIGNUP_CONDITIONS_PDF: encodeURIComponent(
    'Victory Road Circuit - Signup conditions.pdf'
  ),
  LEGAL_NOTICE_PDF: encodeURIComponent('Victory Road Circuit - Legal notice.pdf'),
};
