<div class="main">
  <div class="row">
    <div class="container">
      <div class="content">
        <h4 class="title">{{"SCORE.TITLE" | translate}}</h4>
        <p [innerHTML]="'SCORE.PARAGRAPH' | translate" class="paragraph">
        </p>
      </div>
      <div class="table">
        <h5 class="tableTitle">{{"SCORE.SUBTITLE" | translate}}</h5>
        <ul class="tableItems">
          <div *ngFor="let score of SCORE_SYSTEM">
              <li class="tableItem">
                <div class="tableItemLabel">
                  <p class="tableItemTitle">{{score.title}}</p>
                  <p class="tableItemSubtitle">{{score.subtitle}}</p>
                </div>
                <p class="tableItemPoints">{{score.points}}</p>
              </li>
          </div>
        </ul>
        <p class="paragraph">
          * Only players who advance to day 2 (top cut) earn VR Points
        </p>
      </div>

    </div>

  </div>
  <img class="bigDesktopDecoarationImg" src={{bigDesktopDecoration}} alt="" />
</div>
