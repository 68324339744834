import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { EventsComponent } from './components/events/events.component';
import { ScoreSystemComponent } from './components/score-system/score-system.component';
import { PrizesComponent } from './components/prizes/prizes.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { FooterComponent } from './components/footer/footer.component';
import { DesktopmenuComponent } from './components/desktopmenu/desktopmenu.component';
import { MobilemenuComponent } from './components/mobilemenu/mobilemenu.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { RankingComponent } from './components/ranking/ranking.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    IntroductionComponent,
    EventsComponent,
    ScoreSystemComponent,
    PrizesComponent,
    SponsorsComponent,
    FooterComponent,
    DesktopmenuComponent,
    MobilemenuComponent,
    EventCardComponent,
    RankingComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot([
      {path: 'ranking', component: RankingComponent},
      {path: '', component: HomeComponent}
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
