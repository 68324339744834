<nav class="main">
  <ul class="links">
    <li class="linksItem">
      <a href={{routes.RANKING}}>
        {{"MENU.RANKING" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.RULESET}} target="_blank">
        {{"MENU.RULESET" | translate}}
      </a>
    </li>
  </ul>
</nav>
