import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { routes } from '../../routes';

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss']
})
export class PrizesComponent implements OnInit {

  public PRIZES = [
    {
      title: '1st',
      percentage: '36%',
      with750players: '+$1200',
      with1000players: '+$1600',
      with1500players: '+$2500',
    },
    {
      title: '2nd',
      percentage: '20%',
      with750players: '+$700',
      with1000players: '+$900',
      with1500players: '+$1300',
    },
    {
      title: '3-4th',
      percentage: '10%',
      with750players: '+$350',
      with1000players: '+$450',
      with1500players: '+$700',
    },
    {
      title: '5-8th',
      percentage: '4%',
      with750players: '+$130',
      with1000players: '+$180',
      with1500players: '+$270',
    },
    {
      title: '9-16th',
      percentage: '1%',
      with750players: '+$30',
      with1000players: '+$45',
      with1500players: '+$70',
    },
  ];

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
  }

}
