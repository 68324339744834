import { Component, OnInit } from '@angular/core';
import { routes } from '../../routes';

@Component({
  selector: 'app-mobilemenu',
  templateUrl: './mobilemenu.component.html',
  styleUrls: ['./mobilemenu.component.scss']
})
export class MobilemenuComponent implements OnInit {

  public routes = routes;

  constructor() { }

  ngOnInit(): void {
  }

}
