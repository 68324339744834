<div class="main">
  <div class="row">
    <div class="menu">
      <div class=logo>
        <a
          href={{routes.VICTORY_ROAD}}
          target="__blank"
          rel="noopener noreferrer"
        >
          <img src="assets/vr-logo.png" alt="Logo Victory Road" />
        </a>
      </div>

      <app-desktopmenu></app-desktopmenu>
      <app-mobilemenu></app-mobilemenu>

    </div>
  </div>
  <div class="desktopDecorativeImage">
    <img src={{desktopDecorativeImage}} alt="" />
  </div>

  <div class="row">
    <div class="content">
      <div class="headline">
        <h1 class="title">{{"HOME.TITLE" | translate}}</h1>
        <h3 [innerHTML]="'HOME.SUBTITLE' | translate" class="paragraph">
        </h3>
        <div class="headlineLinks">
          <a href="{{routes.INTRODUCTION}}" class="primaryLink">
            {{"BUTTON.MORE_INFO" | translate}}
          </a>
          <a href="{{routes.TRAILER}}" class="secondaryLink bounce" target="_blank">
            {{"BUTTON.TRAILER" | translate}}
          </a>
        </div>
      </div>
      <div class="circuitLogoContainer">
        <div class="circuitLogo">
          <img class=""
            src={{logo}}
            alt="Logo Victory Road Circuit"
          />
        </div>
      </div>
    </div>
  </div>
</div>
