<nav class="main">
  <ul class="links">
    <li class="linksItem">
      <a href={{routes.INTRODUCTION}}>
        {{"MENU.CIRCUIT" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.EVENTS}}>
        {{"MENU.EVENTS" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.PRIZES}}>
        {{"MENU.PRIZES" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.RANKING}}>
        {{"MENU.RANKING" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.RULESET}} target="_blank">
        {{"MENU.RULESET" | translate}}
      </a>
    </li>
  </ul>
</nav>
