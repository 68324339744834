<div>
  <div class="main">
    <div class="leaderImageContainer">
      <img src="{{event.logo}}" alt="" />
    </div>
    <h5 class="title">{{event.title}}</h5>
    <p class="subtitle">{{event.dates}}</p>
    <p class="subsubtitle">{{event.timezone}}</p>
    <div class="wrapper" *ngIf="event.isActive">
      <a class="buttonAvailable"  href={{event.buttonHref}} target="_blank">
        <span class="buttonText">{{event.buttonLabel}}</span>
      </a>
    </div>
    <a class="buttonNotAvailable" *ngIf="!event.isActive && !event.isFinished">
      {{event.buttonLabel}}
    </a>
    <a class="buttonFinished" *ngIf="!event.isActive && event.isFinished" href={{event.buttonHref}} target="_blank">
      {{event.buttonLabel}}
    </a>
    <p class="infoText">{{event.info}}</p>
  </div>
</div>
