import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { routes } from '../../routes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public vrLogo: string = '/assets/vr-logo.png';
  public logo: string = '/assets/vrcircuit.png';
  public desktopDecorativeImage: string = '/assets/decorative-desktop.png';
  public routes = routes;

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
  }

}
