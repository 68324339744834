<div class="main" id="prizes">
  <div class="row">
  <div class="container">
    <div class="content">
      <h4 class="title">{{"PRIZES.TITLE" | translate}}</h4>
      <p [innerHTML]="'PRIZES.PARAGRAPH' | translate" class="paragraph">
      </p>
    </div>
    <div class="table">
      <h5 class="tableTitle">{{"PRIZES.SUBTITLE" | translate}}</h5>
      <table>
        <tr>
          <th class="tableHeaderTitle">{{"PRIZES.HEADER.PLACE" | translate}}</th>
          <th class="tableHeaderTitle">{{"PRIZES.HEADER.PERCENTAGE" | translate}}</th>
          <th class="tableHeaderTitle">{{"PRIZES.HEADER.750" | translate}}</th>
          <th class="tableHeaderTitle">{{"PRIZES.HEADER.1000" | translate}}</th>
          <th class="tableHeaderTitle">{{"PRIZES.HEADER.1500" | translate}}</th>
        </tr>
        <tr *ngFor="let prize of PRIZES">
            <td>
              <div class="tableItemLabel">
                <p class="tableItemTitle">{{prize.title}}</p>
              </div>
            </td>
            <td>
              <div class="tableItemLabel">
                <p class="tableItemPercentage">{{prize.percentage}}</p>
              </div>
            </td>
            <td>
              <div class="tableItemLabel">
                <p class="tableItemPoints">{{prize.with750players}}</p>
              </div>
            </td>
            <td>
              <div class="tableItemLabel">
                <p class="tableItemPoints">{{prize.with1000players}}</p>
              </div>
            </td>
            <td>
              <div class="tableItemLabel">
                <p class="tableItemPoints">{{prize.with1500players}}</p>
              </div>
            </td>
        </tr>
      </table>
    </div>
  </div>
  </div>
</div>
